import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQueryClient } from "@tanstack/react-query";
import { Typography } from "antd";
import { Form, withFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { Text } from "components/atoms";
import { FormModal } from "components/molecules/Modal";
import { CONTENT_TYPES } from "constants/contentTypes";
import {
    useDeleteIndicatorGroup,
    usePatchIndicatorGroup,
    useGetFilteredIndicatorGroups,
} from "reactQuery/hooks/useIndicatorGroups";
import { useGetAllIndicators } from "reactQuery/hooks/useIndicators";
import { useGetAllInitiativeTypes } from "reactQuery/hooks/useInitiativeTypes";
import { indicatorGroupsKeys } from "reactQuery/keys";
import {
    editInsightSelector,
    insightBeingEditedSelector,
} from "stores/insights/insightSlice";
import { toggleOverlay } from "stores/uiStore/actionTypes";
import { overlayNames } from "stores/uiStore/constants";
import { isShowingSelector } from "stores/uiStore/selectors";

export const getIndicatorOptionLabel = ({ value, category }) =>
    `${value} (${category})`;

export const ERROR_MESSAGES = {
    indicatorIndustryMissing: "An indicator industry is required",
    indicatorCategoryMissing: "An indicator category is required",
    indicatorMissing: "An indicator is required",
};

const editIndicatorStyle = makeStyles((theme) => ({
    form: {
        "&> div": {
            paddingBottom: theme.spacing(2),
            width: "100%",
        },
        "&> div:last-child": {
            paddingBottom: theme.spacing(0),
        },
    },
    formControl: {
        width: "100%",
    },
}));

export const RemoveIndicator = () => {
    return (
        <div>
            <p>
                Are you sure that you would like to remove this Indicator from this
                Insight? Once removed, this action cannot be reversed.
            </p>
        </div>
    );
};

const aNumber = (value) => typeof value === "number";

const editIndicatorSchema = Yup.object().shape({
    selectedIndicator: Yup.object().shape({
        industry_id: Yup.number().required(ERROR_MESSAGES.indicatorIndustryMissing),
        category_id: Yup.number().when("industry_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorCategoryMissing),
            otherwise: Yup.number().notRequired(),
        }),
        id: Yup.number().when("category_id", {
            is: aNumber,
            then: Yup.number().required(ERROR_MESSAGES.indicatorMissing),
            otherwise: Yup.number().notRequired(),
        }),
    }),
});

export const EditIndicatorForm = ({ insight, errors, setFieldValue, values }) => {
    const { selectedIndicator, selectedIndicatorTags, selectedInitiativeTypes } =
        values;
    const classes = editIndicatorStyle();

    const getAllInitiativeTypesQuery = useGetAllInitiativeTypes();
    const getAllIndicatorsQuery = useGetAllIndicators();

    return (
        <Form className={classes.formControl}>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            ...new Map(
                                (getAllIndicatorsQuery.data || []).map((indicator) => [
                                    indicator["industry_id"],
                                    indicator,
                                ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.industry || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["industry_id"] === value["industry_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: value?.["industry_id"],
                                industry: value?.["industry"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-industry"
                                label="Indicator Industry"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.industry_id}
                                helperText={
                                    errors.selectedIndicator?.industry_id || null
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["industry"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...new Map(
                                (getAllIndicatorsQuery.data || [])
                                    .filter(
                                        (indicator) =>
                                            indicator["industry_id"] ===
                                            selectedIndicator?.["industry_id"]
                                    )
                                    .map((indicator) => [
                                        indicator["category_id"],
                                        indicator,
                                    ])
                            ).values(),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.category || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["category_id"] === value["category_id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                industry_id: selectedIndicator["industry_id"],
                                industry: selectedIndicator["industry"],
                                category_id: value?.["category_id"],
                                category: value?.["category"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-category"
                                label="Indicator Category"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.category_id}
                                helperText={
                                    errors.selectedIndicator?.category_id || null
                                }
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicator}
                        disabled={!selectedIndicator?.["category"]}
                        multiple={false}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={[
                            selectedIndicator,
                            ...(getAllIndicatorsQuery.data || []).filter(
                                (indicator) =>
                                    indicator["category_id"] ===
                                    selectedIndicator?.["category_id"]
                            ),
                        ]}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicator", {
                                ...selectedIndicator,
                                value: value?.["value"],
                                id: value?.["id"],
                                indicator_tags: value?.["indicator_tags"],
                                initiative_types: value?.["initiative_types"],
                            });
                            setFieldValue("selectedIndicatorTags", []);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator"
                                label="Indicator"
                                variant="outlined"
                                error={!!errors.selectedIndicator?.id}
                                helperText={errors.selectedIndicator?.id || null}
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedIndicatorTags || []}
                        disabled={!selectedIndicator?.["id"]}
                        multiple={true}
                        loading={getAllIndicatorsQuery.isLoading}
                        options={selectedIndicator?.["indicator_tags"] || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedIndicatorTags", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-indicator-tags"
                                label="Indicator Tags"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>

                <Grid item>
                    <Autocomplete
                        value={selectedInitiativeTypes || []}
                        multiple={true}
                        loading={getAllInitiativeTypesQuery.isLoading}
                        options={getAllInitiativeTypesQuery.data || []}
                        filterSelectedOptions
                        getOptionLabel={(option) => {
                            return option?.value || "";
                        }}
                        getOptionSelected={(option, value) => {
                            return option["id"] === value["id"];
                        }}
                        onChange={(e, value) => {
                            setFieldValue("selectedInitiativeTypes", value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="selected-initiative-types"
                                label="Initiative Types"
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item>
                    {insight.ai_summary_enabled ? (
                        <Typography.Text type="warning">
                            Note: AI Summary has been enabled for this insight. After
                            updating the Indicator field, refresh the page to see the
                            updated insight summary.
                        </Typography.Text>
                    ) : (
                        <Typography.Text type="warning">
                            Note: AI Summary is <strong>disabled</strong> for this
                            insight. You can enable it from the Edit Summary view.
                        </Typography.Text>
                    )}
                </Grid>
            </Grid>
        </Form>
    );
};

export const UnconnectedEditIndicatorModal = ({
    insight,
    isShowingModal,
    isUpdatingInsight,
    toggleOverlay,
    ...formikProps
}) => {
    formikProps.values.patchIndicatorGroupMutation = usePatchIndicatorGroup();
    formikProps.values.deleteIndicatorGroupMutation = useDeleteIndicatorGroup();
    const queryClient = useQueryClient();
    formikProps.values.queryClient = queryClient;

    const id = queryClient.getQueryData(indicatorGroupsKeys.active());
    const filteredIndicatorGroups = useGetFilteredIndicatorGroups(
        { content_type: "insight", object_id: insight?.id },
        (insight && isShowingModal) ?? false
    );
    if (insight && isShowingModal && filteredIndicatorGroups.isSuccess) {
        const indicatorGroup = filteredIndicatorGroups.data.results.filter(
            (group) => group.id === id
        );

        if (!formikProps.values.selectedIndicator) {
            formikProps.values.selectedIndicator = indicatorGroup[0].indicator;
        }
        if (!formikProps.values.selectedIndicatorTags) {
            formikProps.values.selectedIndicatorTags = indicatorGroup[0].indicator_tags;
        }
        if (!formikProps.values.selectedInitiativeTypes) {
            formikProps.values.selectedInitiativeTypes =
                indicatorGroup[0].initiative_types;
        }
    }

    const showRemove = formikProps?.values ? formikProps.values.isShowingRemove : false;
    if (!isShowingModal) {
        return null;
    }

    const editIndicatorFormProps = {
        insight,
        errors: formikProps.errors,
        setFieldValue: formikProps.setFieldValue,
        values: formikProps.values,
    };

    return (
        <FormModal
            handleClose={() => {
                toggleOverlay({ overlay: overlayNames.editIndicatorModal });
            }}
            handleSubmit={() => {
                formikProps.handleSubmit();
            }}
            isSubmitDisabled={isUpdatingInsight}
            isSubmitLoading={isUpdatingInsight}
            isVisible={isShowingModal}
            submitLabel={!showRemove ? "Submit" : "Remove"}
            title={!showRemove ? "Edit Indicator" : "Remove Indicator"}
            isSecondaryDisabled={isUpdatingInsight}
            hasSecondaryButton={true}
            handleSecondaryClick={() => {
                formikProps.setFieldValue("isShowingRemove", !showRemove);
            }}
            isEditModeActive={true}
            isRemoveModeActive={showRemove}
        >
            {showRemove ? (
                <RemoveIndicator />
            ) : (
                <EditIndicatorForm {...editIndicatorFormProps} />
            )}
        </FormModal>
    );
};

export const EnhancedEditIndicatorModal = withFormik({
    enableReinitialize: true,
    handleSubmit: (
        {
            selectedIndicator,
            selectedIndicatorTags,
            selectedInitiativeTypes,
            deleteIndicatorGroupMutation,
            patchIndicatorGroupMutation,
            queryClient,
            isShowingRemove,
        },
        // @ts-ignore - don't bother fixing since this modal
        // should be updated to use ant design best practices
        { props: { insight, toggleOverlay } }
    ) => {
        const indicatorGroupId = queryClient.getQueryData({
            queryKey: indicatorGroupsKeys.active(),
        });

        if (isShowingRemove) {
            deleteIndicatorGroupMutation.mutate({
                indicatorGroupId,
                insightId: insight.id,
            });
        } else {
            const indicatorTagIds = selectedIndicatorTags.map((tag) => tag.id);
            const initiativeTypeIds = selectedInitiativeTypes.map(
                (initiativeType) => initiativeType.id
            );
            patchIndicatorGroupMutation.mutate({
                id: indicatorGroupId,
                content_type: CONTENT_TYPES.INSIGHT,
                indicator: selectedIndicator.id,
                indicator_tags: indicatorTagIds,
                initiative_types: initiativeTypeIds,
                object_id: insight.id,
            });
        }
        toggleOverlay({ overlay: overlayNames.editIndicatorModal });
    },
    mapPropsToValues: () => ({
        selectedIndicator: null,
        selectedIndicatorTags: null,
        selectedInitiativeTypes: null,
        patchIndicatorGroupMutation: null,
        deleteIndicatorGroupMutation: null,
        queryClient: null,
        isShowingRemove: false,
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: editIndicatorSchema,
})(UnconnectedEditIndicatorModal);

const mapStateToProps = (state) => {
    const editInsight = editInsightSelector(state);
    const isShowing = isShowingSelector(state);

    return {
        insight: insightBeingEditedSelector(state as never),
        isShowingModal: isShowing.editIndicatorModal,
        isUpdatingInsight: editInsight.isUpdating,
    };
};
export const ConnectedEditIndicatorModal = connect(mapStateToProps, {
    toggleOverlay: toggleOverlay,
})(EnhancedEditIndicatorModal);

export default ConnectedEditIndicatorModal;
